<template>
  <div>
    <PageHeader
      text="warunki i zobowiązania"
      font="white"
      back="black"
    ></PageHeader>
    <b-container class="content">
      <p>
        Następujące warunki handlowe ("Warunki") są częścią każdej umowy
        sprzedaży i/lub dostawy towarów ("towar") zawieranej przez
        {{ addressText }} ("Sprzedawca"). Wszystkie podane oferty, przyjęte
        zamówienia i udzielone przez Sprzedającego kredyty podlegają
        następującym warunkom.
      </p>
      <ol>
        <li>
          <p class="header">Zachowanie tytułu własności</p>
          <p>
            Kupujący przyjmuje do wiadomości, że towary dostarczone przez
            Sprzedającego pozostają własnością Sprzedającego do czasu otrzymania
            przez Sprzedającego pełnej zapłaty od Kupującego.
          </p>
        </li>
        <li>
          <p class="header">Ceny</p>
          <p>
            Ceny podane przez przedstawiciela Sprzedającego lub opublikowane
            cenniki mogą ulec zmianie bez powiadomienia i nie są wiążące dla
            Sprzedającego. Ceny towarów nie obejmują wszelkich kosztów dostawy i
            ubezpieczenia, które są płatne przez kupującego. Sprzedający
            zastrzega sobie prawo do podwyższenia cen w celu uwzględnienia
            wzrostu kosztów ponoszonych przez Sprzedającego lub z tytułu dostawy
            Towarów. Wszystkie pomyłki pracownicze w cenach podlegają korekcie
            przez Sprzedającego.
          </p>
        </li>
        <li>
          <p class="header">Przyjmowanie zamówień</p>
          <p>
            (a) Przyjęcie Zamówienia przez Sprzedającego podlega niniejszym
            warunkom sprzedaży oraz wszelkim dodatkowym warunkom, które mogą być
            zawarte w cennikach lub ofertach Sprzedającego, zgodnie z którymi
            zamówienie zostało złożone.
          </p>
          <p>
            (b) Niniejsze warunki sprzedaży mogą być zmieniane wyłącznie za
            pisemną zgodą Sprzedawcy.
          </p>
          <p>
            (c) Warunki sprzedaży, o których mowa w pkt 3a mają pierwszeństwo
            przed wszelkimi dodatkowymi lub niespójnymi warunkami zawartymi w
            zamówieniu Kupującego lub w zapytaniu ofertowym Kupującego.
          </p>
        </li>
        <li>
          <p class="header">Akceptacja produktów</p>
          <p>
            Akceptację towaru dostarczonego przez Kupującego uważa się za
            dokonaną po upływie 3 dni od daty dostawy do Kupującego.
          </p>
        </li>
        <li>
          <p class="header">Dostawa</p>
          <p>
            Sprzedawca dołoży wszelkich starań, aby towary zostały dostarczone
            Kupującemu w terminie uzgodnionym przez strony jako termin dostawy,
            ale Dostawca nie ponosi żadnej odpowiedzialności za brak dostawy w
            tym terminie. Sprzedający zastrzega sobie prawo do dostarczenia
            każdego zamówienia w całości lub w części. Wszelkie opłaty
            przewozowe i tranzytowe leżą w gestii Kupującego.
          </p>
        </li>
        <li>
          <p class="header">Fracht i ubezpieczenie</p>
          <p>
            (a) Ubezpieczenie nie jest wliczone w opłatę frachtową.
            Ubezpieczenie, jeżeli jest wymagane, leży w zakresie
            odpowiedzialności nabywcy i musi zostać uzgodnione przed wysyłką
            towarów.
          </p>
          <p>
            (b) Klienci, którzy chcą wyznaczyć inne przedsiębiorstwo przewozowe
            lub sposób transportu, inny niż stosowany przez Sprzedającego, muszą
            dokonać uzgodnień przed wysyłką towarów.
          </p>
          <p>
            (c) Wszystkie poniesione koszty i ubezpieczenie, jeśli jest to
            wymagane, leżą w gestii nabywcy.
          </p>
        </li>
        <li>
          <p class="header">Płatności</p>
          <p>
            Standardowe warunki to przedpłata. Płatność musi być dokonana w
            całości w momencie zakupu. Płatności bezpośrednie mogą być zapłacone
            za pomocą PayPal, Przelwey24 (za pośrednictwem PayPal), kartą
            kredytową (za pośrednictwem PayPal) itp. Pełna lista usługodawców
            może zostać udostępniona przez Sprzedającego w dowolnym momencie.
          </p>
        </li>
        <li>
          <p class="header">Procedura płatności</p>
          <p>
            (a) Istniejący klienci z historią transakcji i ustalonymi warunkami
            płatności:
          </p>
          <ol>
            <li>
              <p>
                Ozparts będzie wysyłał automatyczne wiadomości e-mail z
                przypomnieniem o należnej kwocie w dniu, w którym płatność jest
                wymagana. Wysłanie e-maila oznacza, że do tego dnia płatność nie
                została zaksięgowana.
              </p>
            </li>
            <li>
              <p>
                Po 7 dniach od daty wymaganej płatności za fakturę Ozparts wyśle
                kolejny wiadomość z przypomnieniem i kompletnymi danymi faktury.
              </p>
            </li>
            <li>
              <p>
                15 dni po upływie terminu płatności wynikającej z faktury
                Ozparts wyśle kolejny e-mail z przypomnieniem. W tym czasie
                konto klienta zostanie zawieszone do momentu dokonania
                płatności.
              </p>
            </li>
            <li>
              <p>
                Jeżeli kwota zaległości nie zostanie wypłacona w ciągu 30 dni od
                terminu płatności, Ozparts przekaże sprawę do biura prawnego,
                którego zadaniem będzie wszczęcie procedury odzyskania długu.
              </p>
            </li>
          </ol>
          <p>
            Klienci, którzy regularnie lub w znacznym stopniu zalegają z
            należnymi płatnościami, najprawdopodobniej utracą możliwość
            przedłużenia warunków odroczonej płatności w Ozparts.
          </p>
          <p>(b) Nowi klienci</p>
          <ol>
            <li>
              <p>
                Początkowe zamówienia i transakcje będą wymagały przedpłaty za
                zamówiony towar przed wysyłką.
              </p>
            </li>
            <li>
              <p>
                Po początkowym okresie współpracy (nie krótszym niż 3 miesiące)
                i wystarczającej historii transakcji z przyjemnością omówimy
                kwestię udzielenia kredytu i warunków (wartość kredytu i warunki
                płatności zostaną omówione indywidualnie i bezpośrednio).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="header">Używanie nazwy handlowej i znaków towarowych</p>
          <p>
            OZPARTS jest znakiem towarowym Sprzedającego. Klienci nie mogą
            używać OZPARTS, {{ addressText }} nazwy handlowej lub znaków
            towarowych, ani żadnej ich części, w ramach nazwy klienta. Klientom
            nie przysługuje prawo, interes ani roszczenie do takich nazw
            handlowych lub znaków towarowych.
          </p>
        </li>
        <li>
          <p class="header">Katalogi, cenniki i referencje techniczne</p>
          <p>
            Katalogi, cenniki i referencje techniczne dostarczone Nabywcy przez
            Dostawcę w formie drukowanej lub elektronicznej pozostają własnością
            Dostawcy przez cały czas i nie mogą być powielane w żaden sposób bez
            pisemnej zgody Dostawcy. Dostawca nie ponosi odpowiedzialności za
            jakiekolwiek błędy lub pominięcia we wspomnianych katalogach,
            cennikach i referencjach technicznych.
          </p>
        </li>
        <li>
          <p class="header">Rejestracja w systemie LUCID</p>
          <p>
            Jesteśmy zarejestrowani w Zentrale Stelle Verpackungsregister
            (Central Agency Packaging Register – 'ZSVR'). Nasz numer w
            rejestracji LUCID to: DE3891082047406.
          </p>
        </li>
      </ol>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageHeader from "@/components/common/PageHeader";

export default {
  name: "TermsPl",

  components: { PageHeader },

  computed: {
    ...mapState(["userRegionData"]),

    currentRegion() {
      return this.userRegionData.location.name;
    },

    addressText() {
      return this.currentRegion !== "NL"
        ? "OZPARTS PL sp. z o.o."
        : "OZPARTS B.V.";
    }
  }
};
</script>
