var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-content"},[_c('ol',[_vm._m(0),_c('li',[_c('p',{staticClass:"header",attrs:{"id":"return"}},[_vm._v("Can I return items purchased?")]),_c('p',[_vm._v(" If you are not satisfied with your purchase, please follow the instructions below to return the item. ")]),_c('ol',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',[(_vm.currentRegion === 'NL')?_c('p',[_vm._v(" Please send returns to: "),_c('br'),_vm._v("OZPARTS B.V. "),_c('br'),_vm._v("Geloërveld, Blauwwater 15D, "),_c('br'),_vm._v("5951 DB Belfeld, Netherlands ")]):_c('p',[_vm._v(" Please send returns to: "),_c('br'),_vm._v("OZPARTS PL sp. z o.o. "),_c('br'),_vm._v("Mazowiecka 46-48 "),_c('br'),_vm._v("87-100 Toruń, Poland ")])])])]),_vm._m(9),_vm._m(10),_vm._m(11),_c('li',[_c('p',{staticClass:"header",attrs:{"id":"shipping"}},[_vm._v("Shipping Options")]),(_vm.currentRegion === 'NL')?_c('Nl'):_c('Eu')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header"},[_vm._v("Did you send my shipment out?")]),_c('p',[_vm._v(" If you received an invoice from our system (sent to your e-mail address which is set up as a \"main address\" in our system) it means that your shipment left our warehouse. Name of the carrier and tracking number are included on the invoice (at the top-right corner). So if you would like to check the progress of the shipment, please note the following instructions: ")]),_c('p',[_vm._v(" UPS: Please access: "),_c('a',{attrs:{"href":"http://www.ups.com/content/pl/en/index.jsx","target":"_blank"}},[_vm._v("http://www.ups.com/content/pl/en/index.jsx")]),_c('br'),_vm._v("Please enter full tracking number as on the invoice. You will initially see actual tracking info. ")]),_c('p',[_vm._v(" EMS/Pocztex: Please access: "),_c('a',{attrs:{"href":"http://www.pocztex.pl/dokl_inf_telef.php","target":"_blank"}},[_vm._v("http://www.pocztex.pl/dokl_inf_telef.php")]),_c('br'),_vm._v("Please enter full tracking number as on the invoice (or sent separately) in the “indentyfikator” field and press “szukaj”. You may also scroll down and click on your country to link to tracing facility offered by your local EMS service supplier. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Returns are acceptable within 30 days from the day of purchase (date of invoice). ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" All returns must be in their original conditions, originally packed (without any signs of opening) and include all original manufacturer's packaging and/or tags. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" If any parts were purchased as a set, it is not possible to return its individual components. In such cases, it is possible to return the entire set only containing all components. Returns of a single set's components' will be rejected and sent back at the customer's cost. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Important! Please, make sure that you include in the parcel "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("filled Return Form (you can download below) together with a copy of the invoice")]),_vm._v(" and send it within 30 days from the day of purchase (date of invoice). ")]),_c('ul',[_c('li',[_c('p',[_vm._v(" You can download our "),_c('a',{attrs:{"href":"https://dba4x4.home.pl/returns/?source=ozperformanceeu","target":"_blank"}},[_vm._v("return form")]),_vm._v(" here. Please, fill it out completely and send in the box with your return. Please include your details, part number and explain the reason of the return. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Any returns not approved by us will be refused. You will be responsible for shipping to us and back to you. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" When shipping returned items, you are responsible to pack and ship items properly so the items do not get damaged or lost in transit. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Credit notes might take a minimum of 5 working days (from the day of the parcel receiving) to be issued ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Please do not return any items via COD we will not accept COD returns ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header",attrs:{"id":"warranties"}},[_vm._v("How can I make a warranty claim?")]),_c('p',[_vm._v(" If you would like to make a warranty claim on any item bought via Ozparts please simply choose brand of the product (from the list below) which you wish to make a claim for and fill up the warranty claim form online. Please note all fields must be filled up and pictures needs to be added. Once form is complete please submit it. Warranty claim will be automatically sent to us. Upon receiving your claim, we will send you a claim number and at the same time it will be passed to the manufacturer who will make a decision on it. If claim is approved credit note or replacement part FOC will be provided. ")]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/ACS","target":"_blank"}},[_vm._v("Warranty Form - Australian Clutch Services")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/DBA","target":"_blank"}},[_vm._v("Warranty Form - Disc Brakes Australia")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/HAWK","target":"_blank"}},[_vm._v("Warranty Form - Hawk Performance")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/TURBOSMART","target":"_blank"}},[_vm._v("Warranty Form - Turbosmart")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/PEDDERS","target":"_blank"}},[_vm._v("Warranty Form - Pedders Suspension")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/WHITELINE","target":"_blank"}},[_vm._v("Warranty Form - Whiteline")])]),_c('p',[_c('a',{attrs:{"href":"https://dba4x4.home.pl/warranty/XFORCE","target":"_blank"}},[_vm._v("Warranty Form - Xforce")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header"},[_vm._v("Why was order placed today not shipped out?")]),_c('p',[_vm._v(" All orders completed by payments via our system till 1:00pm are being dispatched on the same day. If the order was completed after 1:00pm it will be shipped within next working day. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header",attrs:{"id":"payment"}},[_vm._v("How can I make a payment?")]),_c('p',[_vm._v(" Payment for the goods can be made via Credit/Debit card supported by "),_c('a',{attrs:{"href":"https://www.paypal.me/Ozparts","target":"_blank"}},[_vm._v("PayPal")]),_vm._v(" platform. ")])])
}]

export { render, staticRenderFns }