var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-content"},[_c('ol',[_vm._m(0),_c('li',[_c('p',{staticClass:"header",attrs:{"id":"return"}},[_vm._v("Czy mogę zwrócić zakupiony przedmiot?")]),_c('p',[_vm._v(" Jeśli nie jesteś zadowolony z zakupu, proszę postępuj zgodnie z poniższymi instrukcjami, aby zwrócić produkt. ")]),_c('ol',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',[(_vm.currentRegion === 'NL')?_c('p',[_vm._v(" Prosimy zwracać na adres: "),_c('br'),_vm._v("OZPARTS B.V. "),_c('br'),_vm._v("Geloërveld, Blauwwater 15D, "),_c('br'),_vm._v("5951 DB Belfeld, Netherlands ")]):_c('p',[_vm._v(" Prosimy zwracać na adres: "),_c('br'),_vm._v("OZPARTS PL sp. z o.o. "),_c('br'),_vm._v("Mazowiecka 46-48 "),_c('br'),_vm._v("87-100 Toruń, Poland ")])])])]),_vm._m(9),_vm._m(10),_vm._m(11),_c('li',[_c('p',{staticClass:"header",attrs:{"id":"shipping"}},[_vm._v("Opcje wysyłki")]),(_vm.currentRegion === 'NL')?_c('Nl'):_c('Eu')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header"},[_vm._v("Czy wysłaliście moją przesyłkę?")]),_c('p',[_vm._v(" Jeśli otrzymałeś fakturę z naszego systemu (wysłaną na adres e-mail, który jest ustawiony jako główny w naszym systemie) oznacza to, że Twoja przesyłka opuściła nasz magazyn. Nazwa przewoźnika i numer przesyłki znajdują się na fakturze (w prawym górnym rogu). Więc jeśli chcesz sprawdzić postęp przesyłki, prosimy o zapoznanie się z poniższymi instrukcjami: ")]),_c('p',[_vm._v(" UPS: prosimy otworzyć stronę: "),_c('a',{attrs:{"href":"http://www.ups.com/content/pl/en/index.jsx","target":"_blank"}},[_vm._v("http://www.ups.com/content/pl/en/index.jsx")]),_c('br'),_vm._v("Proszę wpisać pełny numer śledzenia tak jak na fakturze. Zobaczysz aktualne informacje o śledzeniu. ")]),_c('p',[_vm._v(" EMS/Pocztex: UPS: prosimy otworzyć stronę: "),_c('a',{attrs:{"href":"http://www.pocztex.pl/dokl_inf_telef.php","target":"_blank"}},[_vm._v("http://www.pocztex.pl/dokl_inf_telef.php")]),_c('br'),_vm._v("Proszę wpisać pełny numer monitorowania jak na fakturze (lub wysłanej osobno) w polu \"indentyfikator\" i nacisnąć \"szukaj\". Możesz również przewinąć w dół i kliknąć na swój kraj, aby przejść do usługi śledzenia oferowanej przez lokalnego dostawcę usług EMS. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Zwroty są przyjmowane w ciągu 30 dni od daty zakupu (daty wystawienia faktury). ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Wszystkie zwroty muszą znajdować się w stanie nienaruszonym, oryginalnie zapakowane (bez oznak otwarcia) i zawierać wszystkie oryginalne opakowania i/lub etykiety producenta. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Jeżeli części zostały zakupione jako zestaw, nie ma mozliwości zwrotu pojedynczych jego składowych. W takich przypadkach istnieje możliwość zwrotu tylko całego zestawu zawierające wszystkie jego składowe. Zwroty pojedynczych składowych zestawów będą odrzucane i odsyłane na koszt kupującego. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Ważne! Upewnij się, że wypełniony formularz zwrotu został załączony do przesyłki zwrotnej (możesz go pobrać poniżej) wraz z kopią faktury, a zwrot został do nas odesłany nie później niż w ciągu 30 dni od dnia zakupu (wystawienia faktury). ")]),_c('ul',[_c('li',[_c('p',[_vm._v(" Możesz pobrać nasz "),_c('a',{attrs:{"href":"https://dba4x4.home.pl/returns/?source=ozperformanceeu&lang=pl","target":"_blank"}},[_vm._v("formularz zwrotu")]),_vm._v(" . Wypełnij go podając swoje dane, numer części i wyjaśnij powód zwrotu. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Wszelkie zwroty towarów nie zatwierdzone przez nas będą odrzucane. Będziesz odpowiedzialny za wysyłkę do nas i z powrotem do Ciebie. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Wysyłając zwracane produkty, jesteś odpowiedzialny za prawidłowe zapakowanie i wysłanie produktów, tak aby nie uległy one uszkodzeniu lub zagubieniu podczas transportu. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Po otrzymaniu zwrotu, nota kredytowa zostanie wystawiona natychmiast po otrzymaniu przesyłki. Może to jednak potrwać do 5 dni roboczych (od dnia otrzymania przesyłki). Wartość noty kredytowej będzie przechowywana na Twoim koncie jako nadpłata do następnego zakupu i będzie po prostu odliczana od następnej płatności. Noty kredytowe będą wystawiane w walucie, w której została wystawiona faktura. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Prosimy nie zwracać żadnych przedmiotów za pobraniem, nie przyjmujemy zwrotów za pobraniem. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header",attrs:{"id":"warranties"}},[_vm._v("Jak mogę złożyć reklamację?")]),_c('p',[_vm._v(" Jeśli chcesz złożyć reklamację do produktu zakupionego za pośrednictwem Ozparts wybierz markę produktu (z poniższej listy), który chcesz reklamować i wypełnij formularz reklamacyjny online. Uwaga: wszystkie pola muszą być wypełnione oraz muszą zostać dodane zdjęcia. Po wypełnieniu formularza prześlij go. Reklamacja zostanie automatycznie przesłana do nas. Po otrzymaniu dokumentu wyślemy Tobie numer roszczenia. Jednocześnie zostanie on przekazany do producenta, który podejmie decyzję. Jeśli reklamacja zostanie zatwierdzona, zostanie wystawiona nota kredytowa lub wysłana część zamienna. ")]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/ACS_PL","target":"_blank"}},[_vm._v("Reklamacja - Australian Clutch Services")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/DBA_PL","target":"_blank"}},[_vm._v("Reklamacja - Disc Brakes Australia")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/HAWK_PL","target":"_blank"}},[_vm._v("Reklamacja - Hawk Performance")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/TURBOSMART_PL","target":"_blank"}},[_vm._v("Reklamacja - Turbosmart")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/PEDDERS_PL","target":"_blank"}},[_vm._v("Reklamacja - Pedders Suspension")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/WHITELINE_PL","target":"_blank"}},[_vm._v("Reklamacja - Whiteline")])]),_c('p',[_c('a',{attrs:{"href":"https://ozparts.eu/warranty/XFORCE_PL","target":"_blank"}},[_vm._v("Reklamacja - Xforce")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header"},[_vm._v(" Dlaczego zamówienie złożone dzisiaj nie zostało wysłane? ")]),_c('p',[_vm._v(" Wszystkie zamówienia złożone za pośrednictwem naszego systemu i opłacone do godziny 14:00 wysyłane są tego samego dnia. Jeśli zamówienie zostało opłacone po godzinie 14:00, zostanie wysłane w następnym dniu roboczym. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"header",attrs:{"id":"payment"}},[_vm._v("Jak mogę dokonać płatności?")]),_c('p',[_vm._v(" Płatność za towar może być dokonana za pomocą karty kredytowej / płatniczej obsługiwanej za pośrednictwem platromy "),_c('a',{attrs:{"href":"https://www.paypal.me/Ozparts","target":"_blank"}},[_vm._v("PayPal")])])])
}]

export { render, staticRenderFns }